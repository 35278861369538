
import { Receita } from '@/core/models/financeiro';
import { EnumCols, PageBase } from '@/core/models/shared';
import { ReceitaService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase { 
    service = new ReceitaService();
    item = new Receita();
    overlay: boolean = false;

    mounted() {
        const filter:any = this.$route.query;
        this.overlay = true;

        this.service.ObterPorId(filter.id, 'Parcelas, Parcelas.Situacao, Parcelas.Baixas')
            .then(
                res => {
                    this.item = res.data;
                    this.item.parcelas = res.data.parcelas.sort((x:any,y:any) => x.parcela - y.parcela);
                },
                err=> {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            }
        )
    }
}
